import React from "react";
// import Parser from "rss-parser";
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx'
import statenames from "../data/state_abbrev_to_name.json";

import ArticlePreview from "../components/ArticlePreview.jsx";
import ExpandableText from '../components/ExpandableText';
import Layout from "../components/layout";
import SEO from "../components/seo";
// import StateInfo from "../components/StateInfo.jsx";
import { USAMapStateSelect, USAMapLegendColor } from '../components/USAMap';
import { REDISTRICTING_TIMELINE_COLORS } from '../components/maps/RedistrictingTimelineAlertMap';
import { RedistrictingDashboardMapTableRow } from '../components/maps/RedistrictingDashboardMap';
import StateMap from "../components/StateMap.jsx";

import "../styles/styles.css";
import "../styles/state.css";

export const relatedPagesForStateQuery = graphql`
query MyQuery($state: String) {
  timeline: allAirtable(filter: {table: {eq: "Timeline Alert"}, data: {State__from_Reforms_: {in: [$state]}}}) {
      nodes {
        id
        data {
          TimelineName
          TimelineState
          TimelineType
          State__from_Reforms_
          Alert
          Final_Map_Deadline
          TimelineDeadlineType {
            id
            childMdx {
              body
            }
          }
          TimelineOtherDates {
            id
            childMdx {
              body
            }
          }
          Proposed_Actions
          TimelineNewsSources {
            id
            childMdx {
              body
            }
          }
          TimelineAdditionalNotes {
            id
            childMdx {
              body
            }
          }
        }
      }
  }
  allAirtable(filter: {table: {eq: "Pages"}, data: {Linked_State_Abbreviation: {eq: $state}}}) {
    nodes {
      id
      table
      data {
        created_time
        last_modified_time
        URL
        Title
      }
    }
  }
  airtable(table: {eq: "Reforms"}, data: {State_Abbreviation: {eq: $state}}) {
    data {
      Congressional_Boundaries
      Legislative_Party
      State_Boundaries
      State_Abbreviation
      Governor_Party
      Status
      State
      Actions {
        id
        childMdx {
          body
        }
      }
      History {
        id
        childMdx {
          body
        }
      }
      Process {
        id
        childMdx {
          body
        }
      }
      Reform {
        id
        childMdx {
          body
        }
      }
      Issues {
        id
        childMdx {
          body
        }
      }
      Sources {
        id
        childMdx {
          body
        }
      }
      Contacts {
        childMdx {
          body
        }
      }
      Representable {
        childMdx {
          body
        }
      }
    }
  }
  allAirtableDashboard(
    filter: {table: {eq: "Dashboard"}, data: {DashboardPlanStatus: {eq: "Live"}, DashboardPlanStateAbbreviation: {eq: $state}}}
    sort: {fields: data___DateAdded, order: DESC}
  ) {
    totalCount
    group(field: data___DashboardPlanStateAbbreviation) {
      nodes {
        id
        table
        data {
          DashboardPlanMapURL
          DashboardPlanScoreURL
          DashboardPlanName
          DashboardPlanStateName
          DashboardPlanStateAbbreviation
          DashboardPlanDescription {
            childMdx {
              body
            }
          }
          DashboardPlanState
          DashboardPlanStatus
          DashboardPlanYear
          DashboardType
          DashboardChamber
          GeneratedMapURL
          GeneratedScoreURL
          Last_Modified
          Created
          DateAdded
        }
        recordId
      }
      totalCount
      field
      fieldValue
    }
    nodes {
      id
      recordId
      data {
        DashboardPlanName
        DashboardPlanStatus
        DashboardPlanMapURL
        DashboardPlanScoreURL
        DashboardPlanYear
        DashboardPlanSourceURL
        DashboardPlanStateName
        DashboardPlanStateAbbreviation
        DashboardFileID
        DashboardType
        DashboardChamber
        DateAdded
        Created
        Last_Modified
        Live_URL
        Local_URL
        GeneratedMapURL
        GeneratedScoreURL
      }
    }
  }
}

`;

const renderStateInfo = (context) => {
  if (!context.data.airtable) return null;
  return <>
  <table>
    <tbody>
      {context.data.airtable.data["Congressional_Boundaries"] &&
        <tr>
          <td><strong>Congressional Boundaries</strong></td>
          <td>{context.data.airtable.data["Congressional_Boundaries"]}</td>
        </tr>
      }
      {context.data.airtable.data["State_Boundaries"] &&
        <tr>
          <td><strong>State Boundaries</strong></td>
          <td>{context.data.airtable.data["State_Boundaries"]}</td>
        </tr>
      }
      {context.data.airtable.data["Governor_Party"] &&
        <tr>
          <td><strong>Governor's Party</strong></td>
          <td>{context.data.airtable.data["Governor_Party"]}</td>
        </tr>
      }
      {context.data.airtable.data["Legislative_Party"] &&
        <tr>
          <td><strong>Legislative Party</strong></td>
          <td>{context.data.airtable.data["Legislative_Party"]}</td>
        </tr>
      }
    </tbody>
  </table>
  {
    context.data.allAirtableDashboard.nodes.length > 0 &&
    <>
    <h2>Scored Maps from the <Link to="/redistricting-report-card">Redistricting Report Card</Link></h2>
    <ExpandableText title={'scored maps'} key={'scored-maps'}>
    <table style={{width: '100%'}}>
    {
      context.data.allAirtableDashboard.nodes.map(planObj => {
        return <RedistrictingDashboardMapTableRow planObj={planObj}/> 
      })
    }
    </table>
    </ExpandableText>
    </>
  }
  {
    (
      // context.data.allJavascriptFrontmatter.nodes.length > 0 ||
      context.data.allAirtable.nodes.length > 0 
      // ||
      // context.data.allMdx.nodes.length > 0
    ) && 
    <h2>Related content</h2>
  }
  <div className="related-articles">
    {/* {context.data.allMdx.nodes.map((mdxNode) => {
      return mdxNode.frontmatter.url && <ArticlePreview
                link={mdxNode.frontmatter.url+'/'}
                header={mdxNode.frontmatter.title} />
    })} */}
    {/* {context.data.allJavascriptFrontmatter.nodes.map((jsNode) => {
      return jsNode.frontmatter.url && <ArticlePreview
                link={jsNode.frontmatter.url}
                header={jsNode.frontmatter.title} />
    })} */}
    {context.data.allAirtable.nodes.map((atNode) => {
      return atNode.data.URL && <ArticlePreview
                link={atNode.data.URL+'/'}
                header={atNode.data.Title} />
    })}
  </div>
  {
    context.data.airtable.data["Representable"] &&
    <>
    <h2>Communities of Interest</h2>
    <MDXRenderer>{context.data.airtable.data["Representable"].childMdx.body}</MDXRenderer>
    </>
  }
  {context.data.timeline.nodes && 
    <>
    <h2>Census-related Redistricting Timeline Delays </h2>
    {
      context.data.timeline.nodes.map(({ data }) => {
        return <>
          <ExpandableText key={data.TimelineType} collapsedDefault={true} headerComponent={
            <>
            <h3>{data.TimelineType}</h3>
            <span>Alert: <USAMapLegendColor color={REDISTRICTING_TIMELINE_COLORS[data.Alert]} />{data.Alert}</span>
            </>
          }>
          <table>
            {data.Final_Map_Deadline && <tr>
              <td><strong>Final Map Deadline</strong></td>
              <td>{data.Final_Map_Deadline}</td>
            </tr>}
            {data.TimelineDeadlineType && data.TimelineDeadlineType.childMdx && <tr>
              <td><strong>Deadline Type</strong></td>
              <td><MDXRenderer>{data.TimelineDeadlineType.childMdx.body}</MDXRenderer></td>
            </tr>}
            {data.TimelineOtherDates && data.TimelineOtherDates.childMdx && <tr>
              <td><strong>Other Dates</strong></td>
              <td><MDXRenderer>{data.TimelineOtherDates.childMdx.body}</MDXRenderer></td>
            </tr>}
            {data.TimelineNewsSources && data.TimelineNewsSources.childMdx && <tr>
              <td><strong>News Sources</strong></td>
              <td><MDXRenderer>{data.TimelineNewsSources.childMdx.body}</MDXRenderer></td>
            </tr>}
            {data.TimelineAdditionalNotes && data.TimelineAdditionalNotes.childMdx && <tr>
              <td><strong>Additional Notes</strong></td>
              <td><MDXRenderer>{data.TimelineAdditionalNotes.childMdx.body}</MDXRenderer></td>
            </tr>}
            {data.Proposed_Actions && <tr>
              <td><strong>Actions Proposed in State</strong></td>
              <td>{data.Proposed_Actions.join(", ")}</td>
            </tr>}
          </table>
          </ExpandableText>
        </>
      })
    }
    </>
  }
  {context.data.airtable.data["Process"] && context.data.airtable.data["Process"].childMdx &&
    <>
    <h2>Process</h2>
    <MDXRenderer>{context.data.airtable.data["Process"].childMdx.body}</MDXRenderer>
    </>
  }
  {context.data.airtable.data["History"] && context.data.airtable.data["History"].childMdx && 
    <ExpandableText headerComponent={<h2>2011 Cycle</h2>}>
      <MDXRenderer>{context.data.airtable.data["History"].childMdx.body}</MDXRenderer>
    </ExpandableText>
  }
  {context.data.airtable.data["Issues"] && context.data.airtable.data["Issues"].childMdx &&
    <>
    <h2>Issues</h2>
    <MDXRenderer>{context.data.airtable.data["Issues"].childMdx.body}</MDXRenderer>
    </>
  }
  {context.data.airtable.data["Reform"] && context.data.airtable.data["Reform"].childMdx &&
    <>
    <h2>Potential Reform</h2>
    <MDXRenderer>{context.data.airtable.data["Reform"].childMdx.body}</MDXRenderer>
    </>
  }
  {context.data.airtable.data["Actions"] && context.data.airtable.data["Actions"].childMdx &&
    <>
    <h2>Actions</h2>
    <MDXRenderer>{context.data.airtable.data["Actions"].childMdx.body}</MDXRenderer>
    </>
  }
  {context.data.airtable.data["Contacts"] && context.data.airtable.data["Contacts"].childMdx &&
    <>
    <h2>Contacts</h2>
    <MDXRenderer>{context.data.airtable.data["Contacts"].childMdx.body}</MDXRenderer>
    </>
  }
  {context.data.airtable.data["Sources"] && context.data.airtable.data["Sources"].childMdx &&
    <>
    <h2>Sources</h2>
    <MDXRenderer>{context.data.airtable.data["Sources"].childMdx.body}</MDXRenderer>
    </>
  }
  </>
}

const ReformsPage = (context) => {
  const stateName = context.pageContext.state;
  let imageProps = {};
  if (context.pageContext.ogImage) {
    imageProps.imagePath = context.pageContext.ogImage.path;
    imageProps.imageWidth = context.pageContext.ogImage.size.width;
    imageProps.imageHeight = context.pageContext.ogImage.size.height;
  }
  return (
    <Layout>
      <SEO 
        title={`${statenames[context.pageContext.state]}`} 
        imagePath={`assets/images/pgp-og-main.png`}
        imageWidth={1200}
        imageHeight={630}
      />
      <div className="state-page">
        <div className="map-col">
          <div className="title">
            <span>{statenames[stateName]}</span>
            <StateMap stateName={stateName}/>
            <USAMapStateSelect />
          </div>
        </div>
        <div className="text-col">
          {renderStateInfo(context)}
        </div>
      </div>
    </Layout>
  );
};

export default ReformsPage;
