import React from "react";
import { withPrefix } from "gatsby"

import '../styles/ArticlePreview.css';

const ArticlePreview = (props) => {
  return (
    <a href={withPrefix(props.link)} className="ArticlePreview">
      {props.header && <div className="ArticlePreview-Header">{props.header}</div>}
      {props.subtitle && <div className="ArticlePreview-Subtitle">{props.subtitle}</div>}
    </a>
  );
}

export default ArticlePreview;