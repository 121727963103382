import React, { useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import USAMap from "../USAMap";
import STATENAMES from "../../data/state_abbrev_to_name.json";

import "../../styles/redistricting-dashboard-homepage.css";
import eilLogoImage from "../../img/eil.png";
import repusLogoImage from "../../img/repus-logo.svg";

export const RedistrictingDashboardMapTableRow = ({ planObj }) => {
  return <tr>
    <td>
      <Link to={`/redistricting-report-card?planId=${planObj.recordId}`}>
        {planObj.data['DashboardPlanName']}
      </Link>
    </td>
    <td>
      <p>{planObj.data['DashboardType'] === 'report-card' ? 'Graded' : 'Metrics calculated'}</p>
    </td>
    <td>
      <em>{new Date(planObj.data['DateAdded']).toDateString()}</em>
    </td>
  </tr>
}

const RedistrictingDashboardMap = (props) => {
  const data = useStaticQuery(graphql`
  query dashboardHomeQuery {
    allAirtableDashboard(
      filter: {table: {eq: "Dashboard"}, data: {DashboardPlanStatus: {eq: "Live"}}}
      sort: {fields: data___DateAdded, order: DESC}
    ) {
      totalCount
      group(field: data___DashboardPlanStateAbbreviation) {
        nodes {
          id
          table
          data {
            DashboardPlanMapURL
            DashboardPlanScoreURL
            DashboardPlanName
            DashboardPlanStateName
            DashboardPlanStateAbbreviation
            DashboardPlanDescription {
              childMdx {
                body
              }
            }
            DashboardPlanState
            DashboardPlanStatus
            DashboardPlanYear
            DashboardType
            DashboardChamber
            DashboardFinalPlan
            DashboardLitigationPlan
            DashboardTabledPlan
            GeneratedMapURL
            GeneratedScoreURL
            Last_Modified
            Created
            DateAdded
          }
          recordId
        }
        totalCount
        field
        fieldValue
      }
      nodes {
        id
        recordId
        data {
          DashboardPlanName
          DashboardPlanStatus
          DashboardPlanMapURL
          DashboardPlanScoreURL
          DashboardPlanYear
          DashboardPlanSourceURL
          DashboardPlanStateName
          DashboardPlanStateAbbreviation
          DashboardFileID
          DashboardType
          DashboardChamber
          DashboardFinalPlan
          DashboardLitigationPlan
          DashboardTabledPlan
          DateAdded
          Created
          Last_Modified
          Live_URL
          Local_URL
          GeneratedMapURL
          GeneratedScoreURL
        }
      }
    }
  }`);
    const [mapsByState, setMapsByState] = useState([]);
    const [mapsByRecent, setMapsByRecent] = useState([]);
    const [dataFormat, setDataFormat] = useState('recent');
    const [filterByMapType, setFilterByMapType] = useState('');
    const [showInLitigationMaps, setShowInLitigationMaps] = useState(false);
    const [showFinalMaps, setShowFinalMaps] = useState(false);
    const [showTabledMaps, setShowTabledMaps] = useState(false);
    const [selectedStateFromMap, setSelectedStateFromMap] = useState(null);

    useEffect(() => {
      if (data) {
        let nodes = data.allAirtableDashboard.nodes
          .filter((planObj) => {
            if (selectedStateFromMap && !planObj.data['DashboardPlanStateAbbreviation'].includes(selectedStateFromMap)) return false;
            if (filterByMapType && !planObj.data['DashboardChamber'].includes(filterByMapType)) return false;
            if (showInLitigationMaps && !planObj.data['DashboardLitigationPlan']) return false;
            if (showFinalMaps && !planObj.data['DashboardFinalPlan']) return false;
            if (!showTabledMaps && planObj.data['DashboardTabledPlan']) return false;
            return true;
          })
        setMapsByRecent(nodes);

        let group = data.allAirtableDashboard.group
          .filter((groupObj) => {
            if (selectedStateFromMap && groupObj['fieldValue'] !== selectedStateFromMap) return false;
            return groupObj.nodes.filter((planObj) => {
              if (planObj.data['DashboardPlanStatus'] === 'Private') return false;
              if (filterByMapType && !planObj.data['DashboardChamber'].includes(filterByMapType)) return false;
              if (showInLitigationMaps && !planObj.data['DashboardLitigationPlan']) return false;
              if (showFinalMaps && !planObj.data['DashboardFinalPlan']) return false;
              if (!showTabledMaps && planObj.data['DashboardTabledPlan']) return false;
              return true;
            }).length > 0 ? true : false;
          });
        group = group.map(groupObj => {
          return {
            ...groupObj,
            nodes: groupObj.nodes.filter((planObj) => {
              if (planObj.data['DashboardPlanStatus'] === 'Private') return false;
              if (filterByMapType && !planObj.data['DashboardChamber'].includes(filterByMapType)) return false;
              if (showInLitigationMaps && !planObj.data['DashboardLitigationPlan']) return false;
              if (showFinalMaps && !planObj.data['DashboardFinalPlan']) return false;
              if (!showTabledMaps && planObj.data['DashboardTabledPlan']) return false;
              return true;
            })
          }
        })
        setMapsByState(group);
      }
    }, [data, selectedStateFromMap, filterByMapType, showFinalMaps, showTabledMaps]);
    return (
        <USAMap
            SelectedStateFromMap={selectedStateFromMap}
            setSelectedStateFromMap={setSelectedStateFromMap}
            stateData={data.allAirtableDashboard.group}
            hideInfoPanel={false}
            hideStateSelect={true}
            hideStateButton={true}
            dataFilter={(groupObj, state) => {
              return groupObj['fieldValue'] === state;
            }}
            legend={{
              "Final Maps": "darkgoldenrod",
              "In Litigation": 'maroon',
              "Draft Maps": "sandybrown",
              "No Draft Maps": "lightgrey",
            }}
            legendMapFn={(legend, datum) => {
              let hasLitigation = datum.nodes.find(plan => plan.data['DashboardLitigationPlan']);
              let hasFinal = datum.nodes.find(plan => plan.data['DashboardFinalPlan']);
              if (hasLitigation) {
                return legend["In Litigation"]
              }
              else if (hasFinal) {
                return legend["Final Maps"]
              } else if (datum[`totalCount`] > 0) {
                return legend["Draft Maps"]
              } else {
                return legend["No Draft Maps"]
              }
            }}
            key={"redistricting-report-card"}
            mapKey={"redistricting-report-card"}
            mapTitle={<>
            <Link to={`/redistricting-report-card`}>
              <a>
                Redistricting Report Card
              </a>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <img
                  width="100"
                  src={eilLogoImage} />
                <img
                  width="100"
                  src={repusLogoImage} />
              </span>
            </Link>
            Click on a map title to see its score!<br />
            Filter/sort with the map or buttons below
            <div>
              <button
                onClick={() => setFilterByMapType('')}
                style={{
                    background: filterByMapType === ''  ? "#f58025" : ''
                }}
              >
                All
              </button>
              <button
                onClick={() => setFilterByMapType('congressional')}
                style={{
                    background: filterByMapType === 'congressional' ? "#f58025" : ''
                }}
              >
                Congressional
              </button>
              <button
                onClick={() => setFilterByMapType('state')}
                style={{
                    background: filterByMapType === 'state' ? "#f58025" : ''
                }}
              >
                State Legislative
              </button>
            </div>
            <div>
              {selectedStateFromMap ?
                <>
                Showing: <Link to={`/reforms/${selectedStateFromMap}`}>{selectedStateFromMap}</Link>
                <button style={{ background: "#f58025" }} onClick={() => setSelectedStateFromMap(null)}>Show all states</button>
                </>
                : 
                <>
                <button
                  onClick={() => setDataFormat('recent')}
                  style={{
                    background: dataFormat === 'recent' ? "#f58025" : ''
                  }}
                >
                  Sort by recent
                </button>
                <button
                  onClick={() => setDataFormat('state')}
                  style={{
                    background: dataFormat === 'state' ? "#f58025" : ''
                  }}>
                    Sort by state
                  </button>
                </>
              }
            </div>
            <div>
              <button
                style={{
                  background: !showFinalMaps ? "#f58025" : ''
                }} 
                onClick={() =>  {
                  setShowFinalMaps(false);
                  setShowTabledMaps(true);
                }}>
                  Show all maps
              </button>
              <button
                style={{
                  background: showFinalMaps ? "#f58025" : ''
                }} 
                onClick={() => setShowFinalMaps(true)}>
                  Only show final maps
              </button>
              <button
                disabled={showFinalMaps}
                style={{
                  background: showTabledMaps ? "#f58025" : ''
                }} 
                onClick={() => setShowTabledMaps(!showTabledMaps)}>
                  {showTabledMaps ? 'Hide' : 'Show'} tabled maps
              </button>
            </div>
            </>}
            mapLegendTitle={`Scored Maps`}
            statusFieldName={`totalCount`}
            mapSubtitle={<div className="redistricting-dashboard-homepage-map-info">
              {
                dataFormat === 'state' ?
                  <table key={`table-${dataFormat}`}>
                  {mapsByState
                  .map((groupObj) => {
                    return <tbody>
                      <tr key={groupObj}><b><Link to={`/reforms/${groupObj['fieldValue']}`}>{STATENAMES[groupObj['fieldValue']]}</Link></b></tr>
                      {
                        groupObj.nodes.map((planObj) => {
                          return <>
                            <RedistrictingDashboardMapTableRow key={`${dataFormat}-${planObj}`} planObj={planObj} />
                          </>
                        })
                      }
                    </tbody>
                  })}
                  </table>
                  :
                  <table key={`table-${dataFormat}`}>
                    <tbody>
                    {mapsByRecent
                    .map((planObj) => {
                      return <RedistrictingDashboardMapTableRow key={`${dataFormat}-${planObj}`} planObj={planObj} />
                    })}
                    </tbody>
                  </table>
              }
              Showing {mapsByRecent.length} maps 
            </div>}
        />
    );
  }
  
  export default RedistrictingDashboardMap;